import apiStrapi from './apiStrapi'

const fetchObj = {
  getAll: (params) => {
    const res = apiStrapi.get('/tours', { params })
    return res
  },

  getById: (id) => {
    const res = apiStrapi.get(`/tours/${id}`)
    return res
  }
}

export default fetchObj
