import { Checkbox, Input } from '@mantine/core'
import * as Styled from './styles'

import { Link, useNavigate } from 'react-router-dom'
// import PaypalLogo from './imgs/paypal_logo.webp'
import CheckCircleIcon from './imgs/checked_circle_icon.webp'
import { useTranslation } from 'react-i18next' // Importação do useTranslation

import fetchPayment from '../../../../services/payment'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import fetchBooking from '../../../../services/booking'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { handleParseError, replaceHTMLEntities } from './parsers'
import dayjs from 'dayjs'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'

import { ReactComponent as CardIcon } from './imgs/card-icon.svg'
import { ReactComponent as CashIcon } from './imgs/cash-icon.svg'
import { ReactComponent as MbWayIcon } from './imgs/mbway-icon.svg'
import { ReactComponent as MultiIcon } from './imgs/multi-icon.svg'
import { ModalConfirmTransfer } from '../../../../components/ModalConfirmTransfer'
import { InPortal } from 'react-reverse-portal'
import { useModal } from '../../../../hooks/useModal'

const protections = [
  'Ts&Cs de infecção por Covid 19',
  'Falha programada da companhia aérea',
  'Doença ou Lesão',
  'Falha no transporte público',
  'Condições médicas pré-existentes',
  'Avaria Mecânica',
  'Morte de Família Imediata',
  'Intimação judicial',
  'Roubo de Ingressos',
  'Emergência Domiciliar',
  'Complicações na Gravidez',
  'Clima Adverso',
  'Serviço jurídico',
  'Realocado para trabalho',
  'Recall das Forças Armadas e Serviços de Emergência',
  'Mudanças nas Datas dos Exames'
]

const SHARE_RIDE_DISCOUNT = 0.25
const NIGHT_TAX = 9
const ANIMALS_TAX = 10
const CHILDSEATS_TAX = 3
const SURFBOARDS_TAX = 3
const REFOUND_TAX = 4.97

const paymentFunctions = {
  MbWay: fetchPayment.mbway,
  MbMulti: fetchPayment.multibanco,
  CreditCard: fetchPayment.creditCard
}

export const BookingDetails = () => {
  const [tax, setTax] = useState(0)
  const [shareRideDiscount, setShareRideDiscount] = useState(0)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [expandedProtections, setExpandedProtections] = useState(false)

  const { tour, setTour, returnTravel } = useTransferContext()
  const expandableReimbursementProtectionsMatch =
    useMediaQuery('(max-width: 615px)')
  const navigate = useNavigate()

  const { openModal } = useModal()

  const hasGoingNightTax =
    dayjs(tour.dateTime).get('hours') > 19 ||
    (dayjs(tour.dateTime).get('hours') >= 19 &&
      dayjs(tour.dateTime).get('minutes') >= 30) ||
    dayjs(tour.dateTime).get('hours') < 8 ||
    (dayjs(tour.dateTime).get('hours') == 8 &&
      dayjs(tour.dateTime).get('minutes') == 0)

  const hasReturnNightTax =
    dayjs(returnTravel.dateTime).get('hours') > 19 ||
    (dayjs(returnTravel.dateTime).get('hours') >= 19 &&
      dayjs(returnTravel.dateTime).get('minutes') >= 30) ||
    dayjs(returnTravel.dateTime).get('hours') < 8 ||
    (dayjs(returnTravel.dateTime).get('hours') == 8 &&
      dayjs(returnTravel.dateTime).get('minutes') == 0)

  const nightTax =
    hasGoingNightTax && hasReturnNightTax
      ? NIGHT_TAX * 2
      : hasGoingNightTax || hasReturnNightTax
      ? NIGHT_TAX
      : 0

  const goingAnimalsTax = tour.animals ? tour.animalsQuantity * ANIMALS_TAX : 0
  const returnAnimalsTax =
    returnTravel.active && returnTravel.animals
      ? returnTravel.animalsQuantity * ANIMALS_TAX
      : 0

  const goingChildSeatsTax = tour.childseat
    ? (tour.childSeat + tour.boosterSeat + tour.babyChair) * CHILDSEATS_TAX
    : 0
  const returnChildSeatsTax =
    returnTravel.active && returnTravel.childseat
      ? (returnTravel.childSeat +
          returnTravel.boosterSeat +
          returnTravel.babyChair) *
        CHILDSEATS_TAX
      : 0

  const goingSurfboardsTax = tour.surf ? tour.surfQuantity * SURFBOARDS_TAX : 0
  const returnSurfboardsTax =
    returnTravel.active && returnTravel.surf
      ? returnTravel.surfQuantity * SURFBOARDS_TAX
      : 0

  useEffect(() => {
    if (tour.totalPrice) {
      const taxCalculate = returnTravel.active
        ? (tour.totalPrice + returnTravel.totalPrice) * 0.06
        : tour.totalPrice * 0.06
      setTax(taxCalculate)

      if (tour.shareRide) {
        const shareRideDiscountCalculate = returnTravel.active
          ? (tour.totalPrice + returnTravel.totalPrice) * SHARE_RIDE_DISCOUNT
          : tour.totalPrice * SHARE_RIDE_DISCOUNT
        setShareRideDiscount(shareRideDiscountCalculate)
      } else {
        setShareRideDiscount(0)
      }
    }
  }, [
    tour.totalPrice,
    returnTravel.active,
    returnTravel.totalPrice,
    tour.shareRide
  ])

  const totalValue = formatCurrency(
    tour.totalPrice -
      tour.discountAmount +
      nightTax +
      goingAnimalsTax +
      goingSurfboardsTax +
      goingChildSeatsTax +
      (tour.reimbursement ? REFOUND_TAX : 0) +
      tax
  )
    .replace('€', '')
    .trim()
    .replace('.', '')
    .replace(',', '.')

  const goingValue = formatCurrency(
    tour.totalPrice -
      tour.discountAmount -
      // shareRideDiscount / 2 +
      nightTax / 2 +
      goingAnimalsTax +
      goingSurfboardsTax +
      goingChildSeatsTax +
      (tour.reimbursement ? REFOUND_TAX / 2 : 0) +
      tax / 2
  )
    .replace('€', '')
    .trim()
    .replace('.', '')
    .replace(',', '.')

  const returnValue = returnTravel.active
    ? formatCurrency(
        returnTravel.totalPrice -
          // shareRideDiscount / 2 +
          nightTax / 2 +
          returnAnimalsTax +
          returnSurfboardsTax +
          returnChildSeatsTax +
          (tour.reimbursement ? REFOUND_TAX / 2 : 0) +
          tax / 2
      )
        .replace('€', '')
        .trim()
        .replace('.', '')
        .replace(',', '.')
    : undefined

  const handleSubmitPayment = async (e) => {
    e.preventDefault()
    let paymentReference = undefined
    let paymentRedirect = undefined

    let goingValueNumber = Number(goingValue.split('.').join(''))
    const totalValueNumber = Number(totalValue.split('.').join(''))
    console.log(totalValueNumber, 'totalValueNumber')
    const returnValueNumber = returnValue
      ? Number(returnValue.split('.').join(''))
      : 0

    if (returnValue) {
      if (totalValueNumber < goingValueNumber + returnValueNumber)
        goingValueNumber -= 1

      if (totalValueNumber > goingValueNumber + returnValueNumber)
        goingValueNumber += 1
    }

    // if (returnValue) {
    //   if (goingValue + returnValueNumber)
    // }

    setLoadingPayment(true)
    const toastId = toast.loading(t('toast.loading'))
    const amount = Number(
      formatCurrency(
        tour.totalPrice -
          tour.discountAmount +
          nightTax +
          goingAnimalsTax +
          goingSurfboardsTax +
          goingChildSeatsTax +
          (tour.reimbursement ? REFOUND_TAX : 0) +
          tax
      )
        .replace('€', '')
        .trim()
        .replace('.', '')
        .replace(',', '.')
    )

    const goingPayload = {
      status: 'PENDING',
      startingPlace: tour.departureLocation,
      startingLatitude: 0,
      startingLongitude: 0,
      arrivalLatitude: 0,
      arrivalLongitude: 0,
      distanceInKm: 0,
      arrivalPlace: tour.arrivalLocation,
      totalPrice: totalValueNumber,
      paymentMethod:
        tour.paymentMethod === 'Card'
          ? 'CREDIT_CARD'
          : tour.paymentMethod.toUpperCase(),
      paymentStatus: 'PENDING',
      // paymentReference: tour.paymentMethod === 'Cash' ? '' : paymentReference,
      // totalDriverCommission: 50,
      numberOfPassengers: tour.allPassangers,
      transferDate: tour.dateTime,
      flightNumber: tour.flightNumber,
      checkedLuggages: tour.checkedLuggage,
      handLuggages: tour.handLuggage,
      backpacks: tour.backPack,
      surfboards: tour.surfQuantity,
      babySeats: tour.babyChair,
      childSeats: tour.childSeat,
      boosterSeats: tour.boosterSeat,
      animals: tour.animalsQuantity,
      shareRide: tour.shareRide,
      passengerName: tour.passengerName,
      passengerPhoneNumber: tour.passengerPhoneNumber,
      passengerEmail: tour.passengerEmail,
      durationInSeconds: 0,
      strapiTourId: String(tour.value),
      ...(tour.discountId
        ? { appliedCoupon: { id: tour.discountId } }
        : undefined)
    }
    fetchBooking
      .createBooking(goingPayload)
      .then(async (res) => {
        if (res.data.id) {
          if (tour.paymentMethod === 'Card') {
            try {
              const { data } = await paymentFunctions.CreditCard({
                email: tour.passengerEmail,
                amount,
                bookingId: res.data.id,
                id: res.data.id
              })

              const { data: responseData } = data

              if (responseData.transactionStatus === 'Success') {
                toast.update(toastId, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  newestOnTop: false,
                  closeOnClick: true,
                  rtl: false,
                  pauseOnFocusLoss: true,
                  draggable: true,
                  pauseOnHover: true,
                  theme: 'light',
                  type: 'success',
                  closeButton: true,
                  isLoading: false,
                  render: t('toast.successfulPayment')
                })
              }
              // paymentReference = responseData.reference
              paymentRedirect = responseData.redirectUrl
              setTour({
                ...tour,
                // paymentReference: responseData.reference,
                paymentRedirect: responseData.redirectUrl
              })
            } catch (err) {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'error',
                closeButton: true,
                isLoading: false,
                render: t('toast.internalError')
              })
              console.log(err, 'err')

              setLoadingPayment(false)

              return
            }
          }

          if (['MbWay', 'MbMulti'].includes(tour.paymentMethod)) {
            try {
              const { data } = await paymentFunctions[tour.paymentMethod]({
                phoneNumber: 987654321,
                // tour.passengerPhoneNumber,
                email: tour.passengerEmail,
                amount,
                bookingId: res.data.id
              })

              const { data: responseData } = data

              const message = replaceHTMLEntities(responseData.resposta)

              if (responseData.sucesso) {
                toast.update(toastId, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  newestOnTop: false,
                  closeOnClick: true,
                  rtl: false,
                  pauseOnFocusLoss: true,
                  draggable: true,
                  pauseOnHover: true,
                  theme: 'light',
                  type: 'success',
                  closeButton: true,
                  isLoading: false,
                  render: t('toast.successfulPayment')
                })
                // paymentReference = responseData.referencia
                // setTour({
                //   ...tour,
                //   paymentReference: String(responseData.referencia)
                // })
              } else {
                toast.update(toastId, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  newestOnTop: false,
                  closeOnClick: true,
                  rtl: false,
                  pauseOnFocusLoss: true,
                  draggable: true,
                  pauseOnHover: true,
                  theme: 'light',
                  type: 'error',
                  closeButton: true,
                  isLoading: false,
                  render: message
                })

                setLoadingPayment(false)

                return
              }
            } catch (err) {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'error',
                closeButton: true,
                isLoading: false,
                render: t('toast.internalError')
              })

              setLoadingPayment(false)

              return
            }
          }
        }

        toast.update(toastId, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          theme: 'light',
          type: 'success',
          closeButton: true,
          isLoading: false,
          render: 'Reserva feita com sucesso!'
        })

        setLoadingPayment(false)
        if (tour.paymentMethod === 'Card') {
          window.location.href = paymentRedirect
        } else {
          openModal('ModalConfirmTransfer-tour')
        }
        setTour((oldState) => ({
          ...oldState,
          status: res.data.status,
          paymentStatus: res.data.paymentStatus
        }))
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoadingPayment(false)
      })
  }

  const { t } = useTranslation()

  return (
    <form onSubmit={handleSubmitPayment}>
      <Styled.Container>
        <h1>{t('payment')}</h1>
        <Styled.FormRow className="payment-methods">
          <Styled.PaymentCard
            active={tour.paymentMethod === 'Card'}
            onClick={() => setTour({ ...tour, paymentMethod: 'Card' })}
          >
            <CardIcon
              stroke={tour.paymentMethod === 'Card' ? '#27c7ff' : '#222222'}
            />
            <p>Card</p>
          </Styled.PaymentCard>
          <Styled.PaymentCard
            active={tour.paymentMethod === 'Cash'}
            onClick={() => setTour({ ...tour, paymentMethod: 'Cash' })}
          >
            <CashIcon
              stroke={tour.paymentMethod === 'Cash' ? '#27c7ff' : '#222222'}
            />
            <p>Cash</p>
          </Styled.PaymentCard>

          <Styled.PaymentCard
            active={tour.paymentMethod === 'MbMulti'}
            onClick={() => setTour({ ...tour, paymentMethod: 'MbMulti' })}
          >
            <MultiIcon />
            <p>Multibanco</p>
          </Styled.PaymentCard>
          <Styled.PaymentCard
            active={tour.paymentMethod === 'MbWay'}
            onClick={() => setTour({ ...tour, paymentMethod: 'MbWay' })}
          >
            <MbWayIcon />
            <p>MB Way</p>
          </Styled.PaymentCard>
        </Styled.FormRow>
        {tour.paymentMethod === 'Card' && (
          <Styled.CardTax>
            <p>{t('checkoutTour.Taxa de cartão')} 2%</p>
            <strong>€ 0,00</strong>
          </Styled.CardTax>
        )}
        <Styled.ReimbursementWrapper
          expandable={expandableReimbursementProtectionsMatch}
        >
          <Styled.GreenWrapper>
            <Styled.GreenTag>{t('recommended')}</Styled.GreenTag>
            <Checkbox
              disabled={loadingPayment}
              label={t('form.updateMyRefundTerms')}
              size={'lg'}
              color="teal"
              checked={tour.reimbursement}
              onChange={() =>
                setTour({ ...tour, reimbursement: !tour.reimbursement })
              }
            />
            <div className="price">€ 4,97</div>
          </Styled.GreenWrapper>
          <h4>{t('updateReimbursementTermsCover')}</h4>
          <div className="grid-wrapper">
            <Styled.ReimbursementGrid
              expandable={expandableReimbursementProtectionsMatch}
              expanded={
                expandedProtections || !expandableReimbursementProtectionsMatch
              }
            >
              {protections &&
                protections.map((protection) => (
                  <div key={protection}>
                    <img src={CheckCircleIcon} />
                    <span>{t(protection)}</span>
                  </div>
                ))}
            </Styled.ReimbursementGrid>
            {expandableReimbursementProtectionsMatch && (
              <button
                type="button"
                onClick={() => setExpandedProtections((oldState) => !oldState)}
              >
                {expandedProtections ? 'Minimizar' : t('seeMore')}
              </button>
            )}
          </div>
          <Styled.ReimbursementOrangeWrapper>
            <Checkbox disabled={loadingPayment} color="yellow.7" size="xl" />
            <div>
              <span>{t('refundToOriginalPaymentMethod')}</span>
              <span style={{ fontWeight: '400', color: '#808080' }}>
                {t('refundToOriginalPaymentMethodDescription')}
              </span>
            </div>
          </Styled.ReimbursementOrangeWrapper>
        </Styled.ReimbursementWrapper>
        <Styled.PaymentButton disabled={loadingPayment} type="submit">
          {t('checkoutTour.CONTINUAR')}
        </Styled.PaymentButton>
      </Styled.Container>
    </form>
  )
}
