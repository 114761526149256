import { Checkbox } from '@mantine/core'
import * as Styled from './styles'

import { useNavigate } from 'react-router-dom'

import CheckCircleIcon from './imgs/checked_circle_icon.webp'
import { useTranslation } from 'react-i18next' // Importação do useTranslation
import fetchPayment from '../../../../services/payment'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import fetchBooking from '../../../../services/booking'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { replaceHTMLEntities } from './parsers'
import dayjs from 'dayjs'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'

import { ReactComponent as CardIcon } from './imgs/card-icon.svg'
import { ReactComponent as CashIcon } from './imgs/cash-icon.svg'
import { ReactComponent as MbWayIcon } from './imgs/mbway-icon.svg'
import { ReactComponent as MultiIcon } from './imgs/multi-icon.svg'
import { useModal } from '../../../../hooks/useModal'

const protections = [
  'Ts&Cs de infecção por Covid 19',
  'Falha programada da companhia aérea',
  'Doença ou Lesão',
  'Falha no transporte público',
  'Condições médicas pré-existentes',
  'Avaria Mecânica',
  'Morte de Família Imediata',
  'Intimação judicial',
  'Roubo de Ingressos',
  'Emergência Domiciliar',
  'Complicações na Gravidez',
  'Clima Adverso',
  'Serviço jurídico',
  'Realocado para trabalho',
  'Recall das Forças Armadas e Serviços de Emergência',
  'Mudanças nas Datas dos Exames'
]

const SHARE_RIDE_DISCOUNT = 0.25
const NIGHT_TAX = 9
const ANIMALS_TAX = 10
const CHILDSEATS_TAX = 3
const SURFBOARDS_TAX = 3
const REFOUND_TAX = 4.97

const paymentFunctions = {
  MbWay: fetchPayment.mbway,
  MbMulti: fetchPayment.multibanco,
  CreditCard: fetchPayment.creditCard
}

export const BookingDetails = () => {
  const [tax, setTax] = useState(0)
  const [shareRideDiscount, setShareRideDiscount] = useState(0)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [expandedProtections, setExpandedProtections] = useState(false)
  const [paymentReference, setPaymentReference] = useState('')
  const [paymentRedirect, setPaymentRedirect] = useState('')

  const { going, setGoing, returnTravel } = useTransferContext()
  const expandableReimbursementProtectionsMatch =
    useMediaQuery('(max-width: 615px)')
  const navigate = useNavigate()

  const { carSelected } = useTransferContext()

  const { openModal } = useModal()

  const hasGoingNightTax =
    dayjs(going.dateTime).get('hours') > 19 ||
    (dayjs(going.dateTime).get('hours') >= 19 &&
      dayjs(going.dateTime).get('minutes') >= 30) ||
    dayjs(going.dateTime).get('hours') < 8 ||
    (dayjs(going.dateTime).get('hours') == 8 &&
      dayjs(going.dateTime).get('minutes') == 0)

  const hasReturnNightTax =
    dayjs(returnTravel.dateTime).get('hours') > 19 ||
    (dayjs(returnTravel.dateTime).get('hours') >= 19 &&
      dayjs(returnTravel.dateTime).get('minutes') >= 30) ||
    dayjs(returnTravel.dateTime).get('hours') < 8 ||
    (dayjs(returnTravel.dateTime).get('hours') == 8 &&
      dayjs(returnTravel.dateTime).get('minutes') == 0)

  const nightTax =
    hasGoingNightTax && hasReturnNightTax
      ? NIGHT_TAX * 2
      : hasGoingNightTax || hasReturnNightTax
      ? NIGHT_TAX
      : 0

  const goingAnimalsTax = going.animals
    ? going.animalsQuantity * ANIMALS_TAX
    : 0
  const returnAnimalsTax =
    returnTravel.active && returnTravel.animals
      ? returnTravel.animalsQuantity * ANIMALS_TAX
      : 0

  const goingChildSeatsTax = going.childseat
    ? (going.childSeat + going.boosterSeat + going.babyChair) * CHILDSEATS_TAX
    : 0
  const returnChildSeatsTax =
    returnTravel.active && returnTravel.childseat
      ? (returnTravel.childSeat +
          returnTravel.boosterSeat +
          returnTravel.babyChair) *
        CHILDSEATS_TAX
      : 0

  const goingSurfboardsTax = going.surf
    ? going.surfQuantity * SURFBOARDS_TAX
    : 0
  const returnSurfboardsTax =
    returnTravel.active && returnTravel.surf
      ? returnTravel.surfQuantity * SURFBOARDS_TAX
      : 0
  const orderBumpsTax = going.bookingStops
    ? going.bookingStops.reduce((total, item) => total + item.price, 0)
    : 0

  useEffect(() => {
    if (going.totalPrice) {
      const taxCalculate = returnTravel.active
        ? (going.totalPrice + returnTravel.totalPrice) * 0.06
        : going.totalPrice * 0.06
      setTax(taxCalculate)

      if (going.shareRide) {
        const shareRideDiscountCalculate = returnTravel.active
          ? (going.totalPrice + returnTravel.totalPrice) * SHARE_RIDE_DISCOUNT
          : going.totalPrice * SHARE_RIDE_DISCOUNT
        setShareRideDiscount(shareRideDiscountCalculate)
      } else {
        setShareRideDiscount(0)
      }
    }
  }, [
    going.totalPrice,
    returnTravel.active,
    returnTravel.totalPrice,
    going.shareRide
  ])

  const totalValue = formatCurrency(
    going.totalPrice -
      going.discountAmount +
      (returnTravel.active
        ? returnTravel.totalPrice - returnTravel.discountAmount
        : 0) -
      // shareRideDiscount +
      nightTax +
      goingAnimalsTax +
      returnAnimalsTax +
      goingSurfboardsTax +
      returnSurfboardsTax +
      goingChildSeatsTax +
      returnChildSeatsTax +
      orderBumpsTax +
      (going.reimbursement ? REFOUND_TAX : 0) +
      tax
  )
    .replace('€', '')
    .trim()
    .replace('.', '')
    .replace(',', '.')

  const goingValue = formatCurrency(
    going.totalPrice -
      going.discountAmount -
      // shareRideDiscount / 2 +
      nightTax / 2 +
      goingAnimalsTax +
      goingSurfboardsTax +
      goingChildSeatsTax +
      orderBumpsTax +
      (going.reimbursement ? REFOUND_TAX / 2 : 0) +
      tax / 2
  )
    .replace('€', '')
    .trim()
    .replace('.', '')
    .replace(',', '.')

  const returnValue = returnTravel.active
    ? formatCurrency(
        returnTravel.totalPrice -
          returnTravel.discountAmount -
          // shareRideDiscount / 2 +
          nightTax / 2 +
          returnAnimalsTax +
          returnSurfboardsTax +
          returnChildSeatsTax +
          (going.reimbursement ? REFOUND_TAX / 2 : 0) +
          tax / 2
      )
        .replace('€', '')
        .trim()
        .replace('.', '')
        .replace(',', '.')
    : undefined

  console.log(returnTravel, 'TESTE')

  const handleSubmitPayment = async (e) => {
    e.preventDefault()
    let paymentReference = undefined
    let paymentRedirect = undefined

    let goingValueNumber = Number(goingValue.split('.').join(''))
    const totalValueNumber = Number(totalValue.split('.').join(''))
    const returnValueNumber = returnValue
      ? Number(returnValue.split('.').join(''))
      : 0

    console.log({
      sum: goingValueNumber + returnValueNumber,
      total: totalValueNumber
    })

    if (returnValue) {
      if (totalValueNumber < goingValueNumber + returnValueNumber)
        goingValueNumber -= 1

      if (totalValueNumber > goingValueNumber + returnValueNumber)
        goingValueNumber += 1
    }

    setLoadingPayment(true)
    const toastId = toast.loading(t('toast.loading'))
    const amount = Number(
      formatCurrency(
        going.totalPrice -
          going.discountAmount +
          (returnTravel.active
            ? returnTravel.totalPrice - returnTravel.discountAmount
            : 0) -
          // shareRideDiscount +
          nightTax +
          goingAnimalsTax +
          returnAnimalsTax +
          goingSurfboardsTax +
          returnSurfboardsTax +
          goingChildSeatsTax +
          returnChildSeatsTax +
          orderBumpsTax +
          (going.reimbursement ? REFOUND_TAX : 0) +
          tax
      )
        .replace('€', '')
        .trim()
        .replace('.', '')
        .replace(',', '.')
    )

    const goingPayload = {
      status: 'PENDING',
      startingPlace: going.from,
      startingLatitude: going.startingLatitude,
      startingLongitude: going.startingLongitude,
      arrivalLatitude: going.arrivalLatitude,
      arrivalLongitude: going.arrivalLongitude,
      arrivalPlace: going.to,
      distanceInKm: parseFloat((going.distanceMeters / 1000).toFixed(1)),
      category: {
        id: carSelected.id
      },
      totalPrice: returnTravel.active ? goingValueNumber : totalValueNumber,
      paymentMethod:
        going.paymentMethod === 'Card'
          ? 'CREDIT_CARD'
          : going.paymentMethod.toUpperCase(),
      paymentStatus: 'PENDING',
      paymentReference: going.paymentMethod === 'Cash' ? '' : paymentReference,
      // totalDriverCommission: 50,
      numberOfPassengers: going.allPassangers,
      transferDate: going.dateTime,
      flightNumber: going.flightNumber,
      checkedLuggages: going.checkedLuggage,
      handLuggages: going.handLuggage,
      backpacks: going.backPack,
      surfboards: going.surfQuantity,
      babySeats: going.babyChair,
      childSeats: going.childSeat,
      boosterSeats: going.boosterSeat,
      animals: going.animalsQuantity,
      shareRide: going.shareRide,
      passengerName: going.passengerName,
      passengerPhoneNumber: going.passengerPhoneNumber,
      passengerEmail: going.passengerEmail,
      durationInSeconds: going.durationInSeconds,
      bookingStops: going.bookingStops ? going.bookingStops : undefined,
      passengerStops: going.passengerStops,
      ...(going.discountId
        ? { appliedCoupon: { id: going.discountId } }
        : undefined)
    }
    console.log(goingPayload, 'goingPayload')
    fetchBooking
      .createBooking(goingPayload)
      .then(async (res) => {
        if (going.paymentMethod === 'Card') {
          try {
            const { data } = await paymentFunctions.CreditCard({
              email: going.passengerEmail,
              amount,
              bookingId: res.data.id,
              id: res.data.id
            })

            const { data: responseData } = data

            if (responseData.transactionStatus === 'Success') {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'success',
                closeButton: true,
                isLoading: false,
                render: t('toast.successfulPayment')
              })
            }
            paymentRedirect = responseData.redirectUrl
            setGoing({
              ...going,
              paymentRedirect: responseData.redirectUrl
            })
          } catch (err) {
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: t('toast.internalError')
            })
            console.log(err, 'err')

            setLoadingPayment(false)

            return
          }
        }

        if (['MbWay', 'MbMulti'].includes(going.paymentMethod)) {
          try {
            const { data } = await paymentFunctions[going.paymentMethod]({
              phoneNumber: 987654321,
              email: going.passengerEmail,
              amount,
              bookingId: res.data.id
            })

            const { data: responseData } = data

            const message = replaceHTMLEntities(responseData.resposta)

            if (responseData.sucesso) {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'success',
                closeButton: true,
                isLoading: false,
                render: t('toast.successfulPayment')
              })
              // paymentReference = responseData.referencia
              // setGoing({
              //   ...going,
              //   paymentReference: String(responseData.referencia)
              // })
            } else {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'error',
                closeButton: true,
                isLoading: false,
                render: message
              })

              setLoadingPayment(false)

              return
            }
          } catch (err) {
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: t('toast.internalError')
            })

            setLoadingPayment(false)

            return
          }
        }
        if (returnTravel.active) {
          const returnPayload = {
            status: 'PENDING',
            startingPlace: returnTravel.from,
            arrivalPlace: returnTravel.to,
            startingLatitude: returnTravel.startingLatitude,
            startingLongitude: returnTravel.startingLongitude,
            arrivalLatitude: returnTravel.arrivalLatitude,
            arrivalLongitude: returnTravel.arrivalLongitude,
            distanceInKm: parseFloat(
              (returnTravel.distanceMeters / 1000).toFixed(2)
            ),
            category: {
              id: carSelected.id
            },
            totalPrice: returnValueNumber,
            paymentMethod:
              going.paymentMethod === 'Card'
                ? 'CREDIT_CARD'
                : going.paymentMethod.toUpperCase(),
            paymentStatus: 'PENDING',
            paymentReference:
              going.paymentMethod === 'Cash' ? '' : paymentReference,
            // totalDriverCommission: 50,
            numberOfPassengers: returnTravel.allPassangers,
            transferDate: returnTravel.dateTime,
            flightNumber: returnTravel.flightNumber,
            checkedLuggages: returnTravel.checkedLuggage,
            handLuggages: returnTravel.handLuggage,
            backpacks: returnTravel.backPack,
            surfboards: returnTravel.surfQuantity,
            babySeats: returnTravel.babyChair,
            childSeats: returnTravel.childSeat,
            boosterSeats: returnTravel.boosterSeat,
            animals: returnTravel.animalsQuantity,
            shareRide: going.shareRide,
            passengerName: going.passengerName,
            passengerPhoneNumber: going.passengerPhoneNumber,
            passengerEmail: going.passengerEmail,
            durationInSeconds: returnTravel.durationInSeconds,
            ...(returnTravel.discountId
              ? { appliedCoupon: { id: returnTravel.discountId } }
              : undefined)
          }

          await fetchBooking.createBooking(returnPayload)

          if (going.paymentMethod === 'Cash') {
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'success',
              closeButton: true,
              isLoading: false,
              render: 'Transfer agendado com sucesso!'
            })
          }
        }

        setLoadingPayment(false)
        setGoing((oldState) => ({
          ...oldState,
          status: res.data.status,
          paymentStatus: res.data.paymentStatus
        }))

        if (going.paymentMethod === 'Card') {
          window.location.href = paymentRedirect
        } else {
          openModal('ModalConfirmTransfer')
        }
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoadingPayment(false)
      })
    setLoadingPayment(false)
  }

  const { t } = useTranslation()

  return (
    <form onSubmit={handleSubmitPayment}>
      <Styled.Container>
        <h1>{t('payment')}</h1>
        <Styled.FormRow className="payment-methods">
          <Styled.PaymentCard
            active={going.paymentMethod === 'Card'}
            onClick={() => setGoing({ ...going, paymentMethod: 'Card' })}
          >
            <CardIcon
              stroke={going.paymentMethod === 'Card' ? '#27c7ff' : '#222222'}
            />
            <p>Card</p>
          </Styled.PaymentCard>
          <Styled.PaymentCard
            active={going.paymentMethod === 'Cash'}
            onClick={() => setGoing({ ...going, paymentMethod: 'Cash' })}
          >
            <CashIcon
              stroke={going.paymentMethod === 'Cash' ? '#27c7ff' : '#222222'}
            />
            <p>Cash</p>
          </Styled.PaymentCard>

          <Styled.PaymentCard
            active={going.paymentMethod === 'MbMulti'}
            onClick={() => setGoing({ ...going, paymentMethod: 'MbMulti' })}
          >
            <MultiIcon />
            <p>Multibanco</p>
          </Styled.PaymentCard>
          <Styled.PaymentCard
            active={going.paymentMethod === 'MbWay'}
            onClick={() => setGoing({ ...going, paymentMethod: 'MbWay' })}
          >
            <MbWayIcon />
            <p>MB Way</p>
          </Styled.PaymentCard>
        </Styled.FormRow>
        {going.paymentMethod === 'Card' && (
          <Styled.CardTax>
            <p>{t('checkoutTour.Taxa de cartão')} 2%</p>
            <strong>€ 0,00</strong>
          </Styled.CardTax>
        )}
        <Styled.ReimbursementWrapper
          expandable={expandableReimbursementProtectionsMatch}
        >
          <Styled.GreenWrapper>
            <Styled.GreenTag>{t('recommended')}</Styled.GreenTag>
            <Checkbox
              disabled={loadingPayment}
              label={t('form.updateMyRefundTerms')}
              size={'lg'}
              color="teal"
              checked={going.reimbursement}
              onChange={() =>
                setGoing({ ...going, reimbursement: !going.reimbursement })
              }
            />
            <div className="price">€ 4,97</div>
          </Styled.GreenWrapper>
          <h4>{t('updateReimbursementTermsCover')}</h4>
          <div className="grid-wrapper">
            <Styled.ReimbursementGrid
              expandable={expandableReimbursementProtectionsMatch}
              expanded={
                expandedProtections || !expandableReimbursementProtectionsMatch
              }
            >
              {protections &&
                protections.map((protection) => (
                  <div key={protection}>
                    <img src={CheckCircleIcon} />
                    <span>{t(protection)}</span>
                  </div>
                ))}
            </Styled.ReimbursementGrid>
            {expandableReimbursementProtectionsMatch && (
              <button
                type="button"
                onClick={() => setExpandedProtections((oldState) => !oldState)}
              >
                {expandedProtections ? 'Minimizar' : t('seeMore')}
              </button>
            )}
          </div>
          <Styled.ReimbursementOrangeWrapper>
            <Checkbox disabled={loadingPayment} color="yellow.7" size="xl" />
            <div>
              <span>{t('refundToOriginalPaymentMethod')}</span>
              <span style={{ fontWeight: '400', color: '#808080' }}>
                {t('refundToOriginalPaymentMethodDescription')}
              </span>
            </div>
          </Styled.ReimbursementOrangeWrapper>
        </Styled.ReimbursementWrapper>
        <Styled.PaymentButton disabled={loadingPayment} type="submit">
          {t('checkoutTour.CONTINUAR')}
        </Styled.PaymentButton>
      </Styled.Container>
    </form>
  )
}
